import { useState } from "react";

const twichToolUrl = "https://www.youtube.com/watch?v=7WANFOg7Upo";
const FirstSection = () => {
  return (
    <div
      style={{
        backgroundImage: "url(assets/img/landing/landing-bg.png)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="flex flex-col items-center w-full h-[430px] md:h-[720px] lg:h-[981px] relative pt-6"
    >
      <img
        alt="landing-logo"
        src="assets/img/game/logo.png"
        className="w-[187px] md:w-[300px] lg:w-[618px] mb-4"
      />
      <div className="flex items-center justify-center w-[353px] h-[199px] md:w-[600px] md:h-[340px] lg:w-[872px] lg:h-[491px]">
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/watch?v=7WANFOg7Upo&list=PLvEM_7dT9mGC_FfdeOoWvv_cNOIBaFiKh`}
          title="Selected Video"
          allowFullScreen
        ></iframe>
      </div>
      <img
        alt="landing-bg-2"
        src="assets/img/landing/landing-bg-2.png"
        className="w-full hidden md:absolute bg-[#0000009f] lg:h-[960px]"
      />
      <div className="w-full flex flex-col justify-center items-center mt-1 md:mt-8">
        <p className="text-third-hover text-[14px] md:text-[20px]">
          Official Teaser video
        </p>
        <img
          alt="landing-title-line"
          src="assets/img/landing/title-line.png"
          className="w-[300px] md:w-[724px] mb-[13px] md:mb-[32px] mt-2 lg:mt-3 z-[50]"
        />
        <img
          alt="landing-discover-to-your-hero"
          src="assets/img/landing/discover-to-your-hero.png"
          className="w-[300px] md:w-[720px] md:h-[22px] z-[50]"
        />
      </div>
      <div className="absolute bottom-6 md:bottom-10 lg:bottom-20 flex animate-moveUpDown justify-between w-full px-4 md:px-20">
        <img
          alt="landing-arrow-red"
          className="w-[16px] h-[14px] md:w-[23px] md:h-[20px]"
          src="assets/img/landing/arrow-red.png"
        />
        <img
          alt="landing-arrow-blue"
          className="w-[16px] h-[14px] md:w-[23px] md:h-[20px]"
          src="assets/img/landing/arrow-blue.png"
        />
      </div>
    </div>
  );
};
export default FirstSection;
